function dismissableAlert(alertClass, strongMessage, message) {
    var alertHtml = '<div class="alert alert-dismissable ' + alertClass + '" role="alert">';
    alertHtml += '<button type="button" class="close" data-dismiss="alert" aria-label="Close">';
    alertHtml += '<span aria-hidden="true">&times;</span></button>';
    if(strongMessage) {
        alertHtml += '<strong>' + strongMessage + '</strong> ';
    }
    alertHtml += message;
    alertHtml += '</div>';
    return alertHtml;
}
