/*
    shuup/customer_information/macros/theme/address.jinja
*/

function copyAddress(from, to) {
    $("[id^=id_" + from + "]").each(function() {
        var targetName = $(this).attr("id").split("-")[1];
        var $target = $("#id_" + to + "-" + targetName);
        $target.val($(this).val());
    });
}
