$(() => {
    function setMobileNavState(flag) {
        let bodyClasses = document.body.classList;
        if (bodyClasses.contains('mobile-menu-open')) {
            bodyClasses.remove('mobile-menu-open');
        } else {
            bodyClasses.add('mobile-menu-open');
        }
    }

    function isMobileNavOpen() {
        return document.body.classList.contains('mobile-menu-open');
    }

    /* Close site navigation if user clicks outside the nav element
    when the navigation is open */
    $(document).on('click', (e) => {
        if (isMobileNavOpen() && !$(e.target).closest('.main-header .nav-items').length) {
            setMobileNavState(false);
        }
    });

    // Toggle the visibility of site navigation when the menu icon is clicked
    $('#mobile-nav-toggler').click((e) => {
        e.stopPropagation();
        e.preventDefault();
        setMobileNavState(!isMobileNavOpen());
    });
});
